import { useRef, MutableRefObject } from 'react'
import { FormikConfig, FormikValues, useFormik } from 'formik'

class Helper<V extends FormikValues = FormikValues> {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  value = useFormik<V>({ initialValues: {} as V, onSubmit: () => undefined })
}

type InnerRef<V> = FormikConfig<V>['innerRef']

export type FormikInstance<V extends FormikValues = FormikValues> =
  Helper<V>['value']

export type FormikRef<V extends FormikValues = FormikValues> = MutableRefObject<
  FormikInstance<V>
> &
  InnerRef<V>

export const useFormikRef = <
  V extends FormikValues = FormikValues
>(): FormikRef<V> => {
  const formik = useRef<FormikInstance<V>>()
  return formik as FormikRef<V>
}
