import { put, call, takeEvery } from '@redux-saga/core/effects'
import type { SagaIterator } from 'redux-saga'
import axios, { Response } from 'utils/axios'
import { undefinedIfEmpty } from 'utils/strings'
import { ERROR_ACTION, START_ACTION, SUCCESS_ACTION } from './constants'
import { submit } from './services'
import type { ErrorAction, StartAction, SuccessAction, Values } from './types'

const mapPayload = (payload: Values): Values => ({
  ...payload,
  password: undefinedIfEmpty(payload.password),
  customerType: undefinedIfEmpty(payload.customerType),
  customerCategory: undefinedIfEmpty(payload.customerCategory),
  role: undefinedIfEmpty(payload.role),
  registrationType: undefinedIfEmpty(payload.registrationType),
  companyName: undefinedIfEmpty(payload.companyName) as string,
  companyAddress: undefinedIfEmpty(payload.companyAddress),
  jobTitle: undefinedIfEmpty(payload.jobTitle),
  gender: undefinedIfEmpty(payload.gender),
  id: undefined,
})

const submitSaga = function* ({ payload }: StartAction): SagaIterator {
  const request = submit(mapPayload(payload), payload.id)
  const { error }: Response<unknown> = yield call(axios.tryRequest, request)
  if (error) {
    yield put<ErrorAction>({
      type: ERROR_ACTION,
      payload: { error: error.details?.body, errorMessage: error.message },
    })
  } else {
    yield put<SuccessAction>({ type: SUCCESS_ACTION })
  }
}

const saga = function* (): SagaIterator {
  yield takeEvery(START_ACTION, submitSaga)
}

export default saga
