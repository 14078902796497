import { useEffect } from 'react'
import type { FormikRef } from '../useFormikRef'
import type { FormikValues } from 'formik'

export interface UseFormikSetSubmittingProps<
  V extends FormikValues = FormikValues
> {
  readonly formikRef: FormikRef<V>
  readonly isSubmitting: boolean
}

export const useFormikSetSubmitting = <V extends FormikValues = FormikValues>({
  isSubmitting,
  formikRef,
}: UseFormikSetSubmittingProps<V>): void => {
  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setSubmitting(isSubmitting)
    }
  }, [isSubmitting, formikRef])
}
