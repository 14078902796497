import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import PrivateRoute from '../containers/PrivateRoute'
import MainPageWrapper from '../containers/MainPageWrapper'
import MainPrivateRoute from '../containers/MainPrivateRoute'
import MainPrivateRouteOutlet from './MainPrivarouteOutlet'
import GalleryListPage from '../containers/GalleryListPage/Loadable'
import GalleryFormPage from '../containers/GalleryFormPage/Loadable'
import EventListPage from '../containers/EventListPage/Loadable'
import EventFormPage from '../containers/EventFormPage/Loadable'
import HomePage from '../containers/HomePage/Loadable'
import LoginPage from '../containers/LoginPage/Loadable'
import ProfilePage from '../containers/ProfilePage/Loadable'
import EditProfilePage from '../containers/EditProfilePage/Loadable'
import EditPasswordPage from '../containers/EditPasswordPage/Loadable'
import ForgotPasswordPage from '../containers/ForgotPasswordPage/Loadable'
import RegisterPage from '../containers/RegisterPage/Loadable'
import ResetPassword from '../containers/ResetPassword/Loadable'
import BimListPage from '../containers/BimListPage/Loadable'
import BimDetailPage from '../containers/BimDetailPage/Loadable'
import BimCreatePage from '../containers/BimCreatePage/Loadable'
import UserListPage from '../containers/UserListPage/Loadable'
import UserDetailPage from '../containers/UserDetailPage/Loadable'
import UserFormPage from 'containers/UserFormPage'
import CertificationListPage from '../containers/CertificationListPage/Loadable'
import CertificationCreatePage from '../containers/CertificationCreatePage/Loadable'
import CertificationDetailPage from '../containers/CertificationDetailPage/Loadable'
import QuizFormPage from '../containers/QuizFormPage/Loadable'
import CertificateListPage from '../containers/CertificateListPage/Loadable'
import CertificateDetailPage from '../containers/CertificateDetailPage/Loadable'
import CertificateRenewalDetailPage from '../containers/CertificateRenewalDetailPage/Loadable'
import MaterialCreatePage from '../containers/MaterialCreatePage/Loadable'
import UserCertificationListPage from '../containers/UserCertificationListPage/Loadable'
import UserCertificationDetailPage from '../containers/UserCertificationDetailPage/Loadable'
import CategoryListPage from '../containers/CategoryListPage/Loadable'
import SupportMessage from '../containers/SupportMessage/Loadable'
import MateriPembelajaran from '../containers/MateriPembelajaran/Loadable'
import CategoryCreatePage from '../containers/CategoryCreatePage/Loadable'
import BrochureListPage from 'containers/BrochureListPage/Loadable'
import BrochureCreatePage from 'containers/BrochureCreatePage/Loadable'
import BrochureDetailPage from 'containers/BrochureDetailPage/Loadable'
import BrochureImageCreatePage from 'containers/BrochureImageCreatePage/Loadable'
import MateriPembelajaranCreatePage from 'containers/MateriPembelajaranCreatePage/Loadable'
import PricelistPage from 'containers/PricelistPage/Loadable'
import EventDetailPage from 'containers/EventDetailPage/Loadable'
import QuizDetailPage from 'containers/QuizDetailPage/Loadable'
import PricelistFormPage from 'containers/PricelistFormPage/Loadable'
import PricelistDetailPage from 'containers/PricelistDetailPage/Loadable'
import TrackingPage from 'containers/TrackingPage/Loadable'
import MateriPembelajaranDetail from 'containers/MateriPembelajaranDetail/Loadable'
import TrainingParticipant from 'containers/TrainingParticipant/Loadable'
import TrainingParticipantDetail from 'containers/TrainingParticipantDetail/Loadable'
import TrainingParticipantCreate from 'containers/TrainingParticipantCreate/Loadable'

const Router: FC = () => (
  <Routes>
    <Route path="/" element={<MainPrivateRouteOutlet />}>
      <Route index element={<HomePage />} />
      <Route path="support-message" element={<SupportMessage />} />
      <Route path="user" element={<UserListPage />} />
      <Route path="user/:id/detail" element={<UserDetailPage />} />
      <Route path="user/create" element={<UserFormPage />} />
      <Route path="user/:id/edit" element={<UserFormPage />} />
      <Route path="certificate" element={<CertificateListPage />} />
      <Route
        path="certificate/:id/detail"
        element={<CertificateDetailPage />}
      />
      <Route
        path="certificate-renewal/:id/detail"
        element={<CertificateRenewalDetailPage />}
      />
      <Route path="/brochure" element={<BrochureListPage />} />
      <Route path="/brochure/create" element={<BrochureCreatePage />} />
      <Route path="/brochure/:id/edit" element={<BrochureCreatePage />} />
      <Route path="/brochure/:id/detail" element={<BrochureDetailPage />} />
      <Route
        path="/brochure/:brochureId/image/create"
        element={<BrochureImageCreatePage />}
      />
      <Route
        path="/brochure/:brochureId/image/:id/edit"
        element={<BrochureImageCreatePage />}
      />
    </Route>
    <Route
      path="/bim"
      element={
        <MainPrivateRoute title="Bim">
          <BimListPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/bim/:id/detail"
      element={
        <MainPrivateRoute titles={['Bim', 'Detail']}>
          <BimDetailPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/bim/create"
      element={
        <MainPrivateRoute titles={['Bim', 'Create']}>
          <BimCreatePage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/bim/:id/edit"
      element={
        <MainPrivateRoute
          titles={['Bim', 'Edit']}
          element={<BimCreatePage />}
        />
      }
    />
    <Route
      path="/gallery"
      element={
        <MainPrivateRoute title="Gallery" element={<GalleryListPage />} />
      }
    />
    <Route
      path="/gallery/create"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Gallery', 'Create']}>
              <GalleryFormPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/gallery/:id/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Gallery', 'Edit']}>
              <GalleryFormPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/certification"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" title="Certification">
              <CertificationListPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/certification/create"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Certification', 'Create']}
            >
              <CertificationCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/certification/:id/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Certification', 'Edit']}>
              <CertificationCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/certification/:id/detail"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Certification', 'Detail']}
            >
              <CertificationDetailPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/quiz/create"
      element={
        <MainPrivateRoute titles={['Quiz', 'Create']}>
          <QuizFormPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/quiz/:id/edit"
      element={
        <MainPrivateRoute titles={['Quiz', 'Edit']}>
          <QuizFormPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/quiz/:id/detail"
      element={
        <MainPrivateRoute titles={['Quiz', 'Detail']}>
          <QuizDetailPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/material/create"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Material', 'Create']}>
              <MaterialCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/material/:id/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Material', 'Edit']}>
              <MaterialCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/certification-session"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['User Certification']}>
              <UserCertificationListPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/certification-session/:id/detail"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['User Certification', 'Detail']}
            >
              <UserCertificationDetailPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/materi-pembelajaran"
      element={
        <MainPrivateRoute title="Materi Pembelajaran">
          <MateriPembelajaran />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/materi-pembelajaran/create"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Materi Pembelajaran', 'Create']}
            >
              <MateriPembelajaranCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/materi-pembelajaran/:id/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Materi Pembelajaran', 'Edit']}
            >
              <MateriPembelajaranCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/materi-pembelajaran/:id/detail"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Materi Pembelajaran', 'Detail']}
            >
              <MateriPembelajaranDetail />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/training-participant"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Training Participant']}>
              <TrainingParticipant />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/training-participant/create"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Training Participant', 'Create']}
            >
              <TrainingParticipantCreate />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/training-participant/:id/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Training Participant', 'Edit']}
            >
              <TrainingParticipantCreate />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/training-participant/:id/detail"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Training Participant', 'Detail']}
            >
              <TrainingParticipantDetail />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/event"
      element={
        <MainPrivateRoute title="Event">
          <EventListPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/event/create"
      element={
        <MainPrivateRoute titles={['Event', 'Create']}>
          <EventFormPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/event/:id/edit"
      element={
        <MainPrivateRoute titles={['Event', 'Edit']}>
          <EventFormPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/event/:id/detail"
      element={
        <MainPrivateRoute titles={['Event', 'Detail']}>
          <EventDetailPage />
        </MainPrivateRoute>
      }
    />
    <Route
      path="/profile"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" title="Profile">
              <ProfilePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/profile/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Profile', 'Edit']}>
              <EditProfilePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/profile/edit-password"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper
              key="main-page"
              titles={['Profile', 'Edit Password']}
            >
              <EditPasswordPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/category"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" title="Category">
              <CategoryListPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/category/create"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Category', 'Create']}>
              <CategoryCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/category/:id/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Category', 'Edit']}>
              <CategoryCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/category/:id/detail"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Category', 'Detail']}>
              <CategoryCreatePage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/pricelist"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" title="Pricelist">
              <PricelistPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/pricelist/create"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Pricelist', 'Create']}>
              <PricelistFormPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/pricelist/:id/edit"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Pricelist', 'Edit']}>
              <PricelistFormPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/pricelist/:id/detail"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" titles={['Pricelist', 'Detail']}>
              <PricelistDetailPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route
      path="/tracking"
      element={
        <PrivateRoute
          element={
            <MainPageWrapper key="main-page" title="Tracking">
              <TrackingPage />
            </MainPageWrapper>
          }
        />
      }
    />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/register/:token" element={<RegisterPage />} />
    <Route path="/reset-token" element={<ForgotPasswordPage />} />
    <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
  </Routes>
)

export default Router
