import Container from 'components/Container'
import MuiContainer from '@mui/material/Container'
import { FC, useEffect, useMemo } from 'react'
import { useFormikRef } from 'hooks/useFormikRef'
import { useFormikSetSubmitting } from 'hooks/useFormikSetSubmitting'
import { Formik } from 'formik'
import useValidationSchema from './useValidationSchema'
import Form from './Form'
import { Values } from './types'
import saga from './saga'
import reducer from './reducer'
import useSelector from './useSelector'
import useDispatch from './useDispatch'
import { useInjectReducer } from 'utils/injectReducer'
import { useInjectSaga } from 'utils/injectSaga'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom'
import Snackbar from 'components/Snackbar'
import AlertDialog from 'components/AlertDialog'
import useRedux from './Load/useRedux'
import FullProgressErrorRetry from 'components/FullProgressErrorRetry'
import { HeaderTitle, useSetHeaderTitle } from 'Context/HeaderTitle'

interface RouteParams {
  readonly id?: string
}

const initialValues = {
  name: '',
  email: '',
  phone: '',
  companyName: '',
  companyAddress: '',
  jobTitle: '',
  gender: '',
  role: '',
  customerType: '',
  customerCategory: '',
  registrationType: '',
  password: '',
}

const UserFormPage: FC = () => {
  useInjectReducer({ key: 'userForm', reducer })
  useInjectSaga({ key: 'userForm', saga })
  const state = useSelector()
  const { submit, reset } = useDispatch()
  const validationSchema = useValidationSchema()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = useParams() as unknown as RouteParams
  const id = params.id ? parseInt(params.id, 10) : false
  const [loadState, loadDispatch] = useRedux()
  const formikRef = useFormikRef<Values>()
  useFormikSetSubmitting<Values>({
    formikRef,
    isSubmitting: state.loading,
  })

  const headerTitle = useMemo<HeaderTitle>(() => {
    const data = loadState.data
    if (data) {
      return [
        { label: 'User', to: '/user' },
        { label: data.name, to: `/user/${data.id}/detail` },
        'Edit',
      ]
    }
    if (id) {
      return [{ label: 'User', to: '/user' }, 'Edit']
    }
    return [{ label: 'User', to: '/user' }, 'Create']
  }, [loadState.data, id])
  useSetHeaderTitle(headerTitle)

  const values = useMemo(() => {
    return loadState.data
      ? {
          ...initialValues,
          id: loadState.data.id,
          name: loadState.data.name,
          email: loadState.data.email,
          phone: loadState.data.phone,
          companyName: loadState.data.companyName,
          companyAddress: loadState.data.companyAddress || '',
          jobTitle: loadState.data.jobTitle || '',
          gender: loadState.data.gender || '',
          role: loadState.data.role,
          customerType: loadState.data.customerType,
          customerCategory: loadState.data.customerCategory,
          registrationType: loadState.data.registrationType,
        }
      : initialValues
  }, [loadState.data])

  useEffect(() => {
    if (id) {
      loadDispatch.load(id)
      return loadDispatch.reset
    }
  }, [id, loadDispatch])

  if (id && (!loadState.data || loadState.data.id !== id)) {
    return (
      <Container>
        <FullProgressErrorRetry
          onRetry={() => loadDispatch.load(id)}
          progress={loadState.loading}
          message={loadState.message}
        />
      </Container>
    )
  }

  const onResetState = () => {
    reset()
    const from = searchParams.get('from')
    navigate(from || '/user')
  }

  return (
    <Container>
      <MuiContainer maxWidth="xs">
        <Formik
          innerRef={formikRef}
          initialValues={values}
          validationSchema={validationSchema}
          component={Form}
          onSubmit={submit}
        />
        <Snackbar
          open={!!state.errorMessage}
          message={state.errorMessage}
          onClose={reset}
          severity="error"
        />
        <AlertDialog
          open={state.success}
          description="User berhasil disimpan"
          positiveText="Ok"
          onClose={onResetState}
          onPositive={onResetState}
        />
      </MuiContainer>
    </Container>
  )
}

export default UserFormPage
